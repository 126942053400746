import React from "react"
import { graphql } from "gatsby"
import classnames from "classnames"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import { pageTypes } from "../types"
import { getContentFromAllFiles } from "../utils/content"

const TermsPage = ({ data, pageContext: { locale } }) => {
  const { 
    html,
    title,
    description,
    subTitle
  } = getContentFromAllFiles(data, 'terms');
  return (
    <Layout locale={locale} content={getContentFromAllFiles(data, 'site')}>
      <SEO title={`${title} | Receiver 2`} description={description} path="/terms/" lang={locale} />
      <Container className={styles.container}>
        <div className={styles.intro}>
          <h1>{title}</h1>
          <h4>{subTitle}</h4>
        </div>
        <hr className={styles.divider} />
        <div className={classnames(styles.content, "html")} dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query termsPageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            html
            frontmatter {
              title
              description
              subTitle
            }
            ...Site
          }
        }
      }
    }
  }
`

TermsPage.propTypes = pageTypes

export default TermsPage
